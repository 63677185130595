import * as React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import { StoreProvider } from "./src/context/store-context"
import { NavigationProvider } from "./src/context/navigation-context"
import { ConfigProvider } from "antd"
import { ApolloProvider } from "@apollo/client"
import { storeClient } from "./store-client"

// const apiVersion = "2021-10"
// const apolloClient = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: new createHttpLink({
//     uri: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/${apiVersion}/graphql`,
//     headers: {
//       "X-Shopify-Storefront-Access-Token":
//         process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
//       Accept: "application/json",
//     },
//   }),
// })

// import "gatsby-plugin-prismic-previews/dist/styles.css"

import "./src/styles/reset.css"
import "./src/styles/tokens.less"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
  // <PrismicPreviewProvider>
  <ApolloProvider client={storeClient}>
    <StoreProvider>
      <NavigationProvider>
        <ConfigProvider>{element}</ConfigProvider>
      </NavigationProvider>
    </StoreProvider>
  </ApolloProvider>
  // </PrismicPreviewProvider>
)
