export const customerTagsAndDescriptions = (): {
  tag: string
  description: string
}[] => {
  return [
    {
      tag: "Wholesale",
      description: "Wholesale",
    },
    {
      tag: "Distributor",
      description: "Distributor",
    },
    {
      tag: "WH-Silver",
      description: "Wholesale: Silver",
    },
    {
      tag: "WH-Gold",
      description: "Wholesale: Gold",
    },
    {
      tag: "WH-Platinum",
      description: "Wholesale: Platinum",
    },
    {
      tag: "COMM",
      description: "Commercial",
    },
    {
      tag: "COMM-Silver",
      description: "Commercial: Silver",
    },
    {
      tag: "COMM-Gold",
      description: "Commercial: Gold",
    },
    {
      tag: "COMM-Plat",
      description: "Commercial: Platinum",
    },
  ]
}
export const potentialCustomerTags = (): string[] => {
  const tags = customerTagsAndDescriptions().map(({ tag }) => tag)
  return tags
}
