import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

const apiVersion = "2021-10"
// const shopifyApiLink = createHttpLink({
//   uri: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/${apiVersion}/graphql`,
// })

// const shopifyAuthLink = setContext((_, { headers }) => {
//   return {
//     headers: {
//       Accept: "application/json",
//       ...headers,
//       "X-Shopify-Storefront-Access-Token":
//         process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
//     },
//   }
// })

export const storeClient = new ApolloClient({
  //   link: shopifyAuthLink.concat(shopifyApiLink),
  uri: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/${apiVersion}/graphql`,
  headers: {
    "X-Shopify-Storefront-Access-Token":
      process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
    Accept: "application/json",
  },
  cache: new InMemoryCache(),
})
