import { gql } from "@apollo/client"

export const CUSTOMER_ORDER_SUMMARY = gql`
  fragment CustomerOrderSummary on Order {
    id
    name
    financialStatus  
    fulfillmentStatus 
    totalPriceV2 {
        amount
        currencyCode
    }
  }
`
