import * as React from "react"
const defaultValues = {
    navKey: null,
    setNavKey: (key) => { }
}

export const NavigationContext = React.createContext(defaultValues)

export const NavigationProvider = ({ children }) => {
    const [navKey, setNavKey] = React.useState<string>(null)
    return (
        <NavigationContext.Provider
            value={{
                navKey,
                setNavKey
            }}
        >
            {children}
        </NavigationContext.Provider>
    )
}