import * as React from "react"
import {
  // ApolloClient,
  gql,
  // InMemoryCache,
  useLazyQuery,
  useMutation,
  // useQuery,
} from "@apollo/client"

import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import { potentialCustomerTags } from "../utils/customerTags"
import { navigate } from "gatsby-link"
import { CUSTOMER_ORDER_SUMMARY } from "../fragments"
import cookie from "js-cookie"
import { Modal, notification } from "antd"

// import { AccountResource } from "./account-resource"
interface OrderSummary {
  id: string
  name: string
}

interface StoreContextProps {
  cart: unknown[]
  priceAdjustments: { [sku: string]: string }
  isOpen: boolean
  loading: boolean
  didJustAddToCart: boolean
  customer?: {
    authenticated?: boolean
    displayName?: string
    email?: string
    tags?: string[]
    orders?: OrderSummary[]
  }
  // children: React.ReactNode
  onOpen: CallableFunction
  onClose: CallableFunction
  addVariantToCart: CallableFunction
  removeLineItem: CallableFunction
  customerAdjustedPrice: CallableFunction
  updateLineItem: CallableFunction
  redirectToCheckout: CallableFunction
  login: CallableFunction
  registerCustomer: CallableFunction
  resetPassword: CallableFunction
  logout: CallableFunction
  client: Client.Client
  checkout: Partial<Client.Cart> /*{
    lineItems: Client.LineItem[]
  }
  */
}

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)
// const client = {
//   ...baseClient,
//   account: new AccountResource(baseClient.graphQLClient),
// }

const potentialTags = potentialCustomerTags()

const defaultValues: StoreContextProps = {
  cart: [],
  isOpen: false,
  loading: false,
  priceAdjustments: {},
  customer: {
    displayName: null,
    email: null,
    authenticated: false,

    tags: [],
    orders: [],
  },
  didJustAddToCart: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  login: () => {},
  logout: () => {},
  registerCustomer: () => {},
  resetPassword: () => {},
  customerAdjustedPrice: (sku: string) => false,
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`
const localStorageTokenKey = `customer_token_id`
const localStorageEmailKey = `customer_email`

const initCustomer = (setStore: any) => {
  const customerToken = cookie.get(localStorageTokenKey)
  if (customerToken) {
    setStore((prevState: StoreContextProps) => {
      return { ...prevState, customerToken }
    })
  }
}

export const StoreProvider: React.FC<StoreContextProps> = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(defaultValues.loading)
  const [priceAdjustments, setPriceAdjustments] = React.useState(
    defaultValues.priceAdjustments
  )
  const [customerEmail, setCustomerEmail] = React.useState(
    cookie.get(localStorageEmailKey)
  )
  const [customerValues, setCustomerValues] = React.useState(
    defaultValues.customer
  )
  const [authenticated, setAuthenticated] = React.useState(
    defaultValues.customer.authenticated
  )
  const [customerAccessToken, setCustomerAccessToken] = React.useState(
    cookie.get(localStorageTokenKey)
  )

  const [didJustAddToCart, setDidJustAddToCart] = React.useState(
    defaultValues.didJustAddToCart
  )

  const [
    getCustomerDetails,
    {
      data: customerData,
      // loading: customerDataLoading,
      // error: customerDataError,
      called: customerDetailsCalled,
      client: apolloClient,
    },
  ] = useLazyQuery(GET_CUSTOMER_DETAILS_QUERY, {
    // onCompleted: (data) => {
    //   console.log("Recieved customer data", { data })
    //   if (data && data.customer) {
    //     setAuthenticated(true)
    //   } else {
    //     setAuthenticated(false)
    //   }
    // },
    onError: (error) => {
      console.error("Error getting customer details", { error })
    },
  })

  const [
    getAccessToken,
    { data: customerAuthData, loading: accessTokenLoading, error: authError },
  ] = useMutation(CREATE_CUSTOMER_ACCESS_TOKEN, {
    client: apolloClient,
    onError: (error) => {
      console.log("Error getting token", { error })
    },
  })

  const [
    registerCustomer,
    {
      data: createdCustomer,
      loading: creatingCustomer,
      error: customerCreatError,
      reset: resetCustomerCreation,
    },
  ] = useMutation(CREATE_CUSTOMER, {
    client: apolloClient,
    onError: (error) => {
      Modal.error({ title: "Registration Error", content: error.toString() })
      // console.log("Error creating customer", { error })
    },
  })

  const [
    resetPassword,
    {
      data: customerRecoverData,
      loading: customerRecoverLoading,
      error: customerRecoverError,
      reset: customerRecoverReset,
    },
  ] = useMutation(RESET_PASSWORD, {
    client: apolloClient,
    onError: (error) => {
      Modal.error({ title: "Reset Error", content: error.toString() })
    },
  })

  const customerAdjustedPrice = React.useCallback(
    (sku: string): false | string => {
      // console.log("Adjusting price for", sku, priceAdjustments)
      if (priceAdjustments && priceAdjustments[sku]) {
        return priceAdjustments[sku]
      }
      return false
    },
    [priceAdjustments]
  )

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  React.useEffect(() => {
    if (customerRecoverData) {
      const { customerUserErrors } = customerRecoverData.customerRecover
      if (customerUserErrors && customerUserErrors.length > 0) {
        Modal.error({
          title: "Password Reset Error",
          content: customerUserErrors[0].message,
          onOk: customerRecoverReset,
        })
      }
    }
    // if (customerRecoverError) {
    //   Modal.error({
    //     title: "Password Reset Error",
    //     content: customerRecoverError[0].message,
    //     onOk: customerRecoverReset,
    //   })
    // }
  }, [customerRecoverData, customerRecoverReset /*, customerRecoverError*/])

  React.useEffect(() => {
    if (createdCustomer) {
      const { customer, customerUserErrors } = createdCustomer.customerCreate
      if (customerUserErrors && customerUserErrors.length > 0) {
        const errors = customerUserErrors.map(({ message, code }) => (
          <p key={code}>{message}</p>
        ))
        Modal.error({
          title: "Unable to register",
          content: errors,
          onOk: resetCustomerCreation,
        })
      } else if (customer && customer.id) {
        console.log(`Created customer ${customer.id}`, customer)
        notification.success({
          message: "Account registered",
          description: "You can now login with your email and password",
        })
        navigate(`/account/login`)
      }
    }
  }, [createdCustomer, resetCustomerCreation])

  React.useEffect(() => {
    if (
      creatingCustomer ||
      accessTokenLoading ||
      // customerDetailsCalled ||
      customerRecoverLoading
    ) {
      // console.log("Setting loading to true")
      setLoading(true)
    } else {
      // console.log("Setting loading to false")
      setLoading(false)
    }
  }, [
    accessTokenLoading,
    creatingCustomer,
    customerDetailsCalled,
    customerRecoverLoading,
  ])

  React.useEffect(() => {
    // console.log("Customer Data", customerData)
    const orders = customerData?.customer?.orders?.edges
      ? customerData.customer.orders.edges.map(
          ({
            node: {
              __typename,
              totalPriceV2: { amount, currencyCode },
              ...rest
            },
          }) => ({
            amount: parseFloat(amount),
            currencyCode,
            ...rest,
          })
        )
      : defaultValues.customer.orders

    setCustomerValues({
      authenticated,
      displayName: customerData?.customer?.displayName
        ? customerData.customer.displayName
        : defaultValues.customer.displayName,
      tags:
        customerData?.customer?.tags &&
        Array.isArray(customerData.customer.tags)
          ? customerData.customer.tags.filter((tag) =>
              potentialTags.includes(tag)
            )
          : defaultValues.customer.tags,
      orders,
    })
  }, [authenticated, customerData])

  React.useEffect(() => {
    if (!!customerAccessToken) {
      if (!customerDetailsCalled) {
        getCustomerDetails({
          variables: { token: customerAccessToken },
        })
      }
      setAuthenticated(true)
    } else {
      setAuthenticated(false)
    }
  }, [customerDetailsCalled, customerAccessToken])

  React.useEffect(() => {
    // if (isBrowser) {
    //   const token = localStorage.getItem(localStorageTokenKey)
    //   if (token) {
    //     setCustomerAccessToken(token)
    //   }
    // }
    if (customerAccessToken && customerEmail) {
      loadCustomerPricing(customerAccessToken, customerEmail)
    }
  }, [customerAccessToken, customerEmail])

  const loadCustomerPricing = async (accessToken, email) => {
    setLoading(true)
    const apiUrl = "/api/customer-pricing"
    const data = {
      customer: {
        email,
      },
    }
    return fetch(apiUrl, {
      method: `POST`,
      headers: {
        "Content-Type": `application/json`,
        "x-gatsby-access-token": accessToken,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        // console.log({ data })
        setPriceAdjustments(data.priceAdjustments)
        return true
      })
      .finally(() => {
        // Set loading to false after the request is done
        setTimeout(() => setLoading(false), 1000)
      })
  }

  const redirectToCheckout = async ({ checkoutUrl = null } = {}) => {
    setLoading(true)
    const redirectCheckoutUrl = "/api/create-token"
    const url = checkoutUrl || checkout.webUrl
    const data = {
      customer: {
        email: customerEmail,
      },
      redirectUrl: url,
    }
    return fetch(redirectCheckoutUrl, {
      method: `POST`,
      headers: {
        "Content-Type": `application/json`,
        // "X-Shopify-Access-Token": customerAccessToken,
        "x-gatsby-access-token": customerAccessToken,
        // "gatsby-Shopify-Access-Token": customerAccessToken,
        // "x-gatsby-test": `test`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        console.log({ data })
        const { url } = data
        if (!url) {
          console.log("No url found")
          return false
        }
        // window.open(url)
        window.location.href = url
        return true
      })
      .finally(() => {
        // Set loading to false after the request is done
        setTimeout(() => setLoading(false), 5000)
      })
    // console.log("Doing redirect checkout")
  }

  const login = async (
    email: string,
    password: string,
    redirectUrl: string | null = "/account/orders"
  ) => {
    setLoading(true)
    try {
      const { data, errors } = await getAccessToken({
        variables: { input: { email, password } },
      })
      const { customerAccessTokenCreate } = data
      const { customerAccessToken, customerUserErrors } =
        customerAccessTokenCreate
      if (!Array.isArray(customerUserErrors) || !customerUserErrors.length) {
        const { accessToken, expiresAt } = customerAccessToken
        // const expiresAtDate = new Date(expiresAt)
        // console.log("Access token updated", { accessToken, expiresAt })
        // setAuthenticated(true)
        // getCustomerDetails({
        //   variables: { token: accessToken },
        // })
        cookie.set(localStorageTokenKey, accessToken, { expires: 25 })
        cookie.set(localStorageEmailKey, email)
        setCustomerAccessToken(accessToken)
        setCustomerEmail(email)
      } else {
        console.log("Error getting token", { customerUserErrors })
        Modal.error({ title: "Error", content: "Unable to login" })
        cookie.remove(localStorageTokenKey)
        cookie.remove(localStorageEmailKey)
        setCustomerEmail(null)
      }
      if (redirectUrl) {
        navigate(redirectUrl)
      }
      setLoading(false)
    } catch (error) {
      console.error("Unable login", { error })
      Modal.error({ title: "Error", content: "Unable to login" })
      setLoading(false)
    }
  }
  const logout = () => {
    // apolloClient.resetStore()
    setCustomerAccessToken(null)
    setCustomerEmail(null)
    setPriceAdjustments(null)
    apolloClient.clearStore()
    cookie.remove(localStorageTokenKey)
    cookie.remove(localStorageEmailKey)
    // setAuthenticated(false)
  }

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        notification.open({
          message: "Added to cart",
          description: "Item added to cart",
          duration: 2,
        })
        navigate("/cart")
        setTimeout(() => {
          return setDidJustAddToCart(false)
        }, 3000)
      })
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }
  // console.log({ customerValues, customerDetailsCalled })
  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        redirectToCheckout,
        customerAdjustedPrice,
        login,
        logout,
        resetPassword,
        registerCustomer,
        checkout,
        customer: customerValues,
        loading,
        // customerData,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const CREATE_CUSTOMER_ACCESS_TOKEN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerUserErrors {
        code
        field
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`

const GET_CUSTOMER_DETAILS_QUERY = gql`
  ${CUSTOMER_ORDER_SUMMARY}
  query ($token: String!) {
    customer(customerAccessToken: $token) {
      id
      displayName
      email
      tags
      orders(first: 10) {
        edges {
          node {
            ...CustomerOrderSummary
            # id
            # name
          }
        }
      }
    }
  }
`
const GET_CUSTOMER_ORDERS_QUERY = gql`
  query ($token: String!) {
    customer(customerAccessToken: $token) {
      id
      displayName
      email
      tags
      orders(first: 1) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`
const GET_PRODUCT_META = gql`
  query ($token: String!) {
    customer(customerAccessToken: $token) {
      id
      displayName
      email
      tags
      orders(first: 1) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

const CREATE_CUSTOMER = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`
const RESET_PASSWORD = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`
