exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-create-tsx": () => import("./../../../src/pages/account/create.tsx" /* webpackChunkName: "component---src-pages-account-create-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-logout-tsx": () => import("./../../../src/pages/account/logout.tsx" /* webpackChunkName: "component---src-pages-account-logout-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders/[...].tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-profile-tsx": () => import("./../../../src/pages/account/profile.tsx" /* webpackChunkName: "component---src-pages-account-profile-tsx" */),
  "component---src-pages-account-reset-tsx": () => import("./../../../src/pages/account/reset.tsx" /* webpackChunkName: "component---src-pages-account-reset-tsx" */),
  "component---src-pages-affiliate-program-tsx": () => import("./../../../src/pages/affiliate-program.tsx" /* webpackChunkName: "component---src-pages-affiliate-program-tsx" */),
  "component---src-pages-affiliates-tsx": () => import("./../../../src/pages/affiliates.tsx" /* webpackChunkName: "component---src-pages-affiliates-tsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-feed-chart-tsx": () => import("./../../../src/pages/feed-chart.tsx" /* webpackChunkName: "component---src-pages-feed-chart-tsx" */),
  "component---src-pages-growers-gallery-tsx": () => import("./../../../src/pages/growers-gallery.tsx" /* webpackChunkName: "component---src-pages-growers-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-elements-index-tsx": () => import("./../../../src/pages/learn/elements/index.tsx" /* webpackChunkName: "component---src-pages-learn-elements-index-tsx" */),
  "component---src-pages-learn-elements-prismic-chemical-element-uid-tsx": () => import("./../../../src/pages/learn/elements/{PrismicChemicalElement.uid}.tsx" /* webpackChunkName: "component---src-pages-learn-elements-prismic-chemical-element-uid-tsx" */),
  "component---src-pages-learn-faq-index-tsx": () => import("./../../../src/pages/learn/faq/index.tsx" /* webpackChunkName: "component---src-pages-learn-faq-index-tsx" */),
  "component---src-pages-learn-faq-prismic-faq-data-category-uid-index-tsx": () => import("./../../../src/pages/learn/faq/{PrismicFaq.data__category__uid}/index.tsx" /* webpackChunkName: "component---src-pages-learn-faq-prismic-faq-data-category-uid-index-tsx" */),
  "component---src-pages-learn-faq-prismic-faq-data-category-uid-prismic-faq-uid-tsx": () => import("./../../../src/pages/learn/faq/{PrismicFaq.data__category__uid}/{PrismicFaq.uid}.tsx" /* webpackChunkName: "component---src-pages-learn-faq-prismic-faq-data-category-uid-prismic-faq-uid-tsx" */),
  "component---src-pages-learn-index-tsx": () => import("./../../../src/pages/learn/index.tsx" /* webpackChunkName: "component---src-pages-learn-index-tsx" */),
  "component---src-pages-press-index-tsx": () => import("./../../../src/pages/press/index.tsx" /* webpackChunkName: "component---src-pages-press-index-tsx" */),
  "component---src-pages-press-prismic-news-article-uid-tsx": () => import("./../../../src/pages/press/{PrismicNewsArticle.uid}.tsx" /* webpackChunkName: "component---src-pages-press-prismic-news-article-uid-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-store-locator-tsx": () => import("./../../../src/pages/store-locator.tsx" /* webpackChunkName: "component---src-pages-store-locator-tsx" */),
  "component---src-templates-prismic-page-tsx": () => import("./../../../src/templates/prismic-page.tsx" /* webpackChunkName: "component---src-templates-prismic-page-tsx" */),
  "component---src-templates-product-category-tsx": () => import("./../../../src/templates/product-category.tsx" /* webpackChunkName: "component---src-templates-product-category-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-testimonial-page-tsx": () => import("./../../../src/templates/testimonial-page.tsx" /* webpackChunkName: "component---src-templates-testimonial-page-tsx" */),
  "component---src-templates-testimonials-list-tsx": () => import("./../../../src/templates/testimonials-list.tsx" /* webpackChunkName: "component---src-templates-testimonials-list-tsx" */)
}

